import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { authState } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getAuth, setPersistence, browserLocalPersistence, } from  'firebase/auth';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { environment } from 'src/environments/environment';
import { UserInterface } from '../interfaces/user.interface';
import { UserService } from './user.service';
import { User } from '../models/user.model';

@Injectable()
export class AuthService {
  userDetails: UserInterface = null;
  readonly user$: Observable<UserInterface | undefined>;

  constructor(
    private readonly router: Router,
    private readonly firestoreAuth: AngularFireAuth,
    private readonly userService: UserService,
  ) {
    const auth = getAuth();
    this.user$ = from(setPersistence(auth, browserLocalPersistence)).pipe(
      switchMap(() => authState(auth)),
      switchMap((authUser) => {
        if (!authUser) {
          this.userDetails = undefined;
          return of({ authUser: undefined, user: undefined });
        }
        return this.userService.get(authUser.uid).pipe(
          map((user) => ({
            authUser,
            user,
          }))
        );
      }),
      tap(({ authUser }) => {if (!authUser) this.toLogin();}),
      map(({ authUser, user }) => {
        if (!authUser) return;

        user.emailVerified = authUser.emailVerified;
        user.lastSignInTime = firebase.firestore.Timestamp.now();
        this.userDetails = new User(user);
        return this.userDetails;
      }),
    );
  }

  async signIn(email: string, password: string) {
    const credential = await this.firestoreAuth.signInWithEmailAndPassword(email, password);
    const user = await this.updateUserData(credential.user);
    this.updateSignIngTime(user);

    if (credential.user !== null && !credential.user.emailVerified) {
      await credential.user.sendEmailVerification();
    }

    return user;
  }

  signUp(email: string, password: string): Observable<firebase.User> {
    return from(this.getSecondaryApp().auth().createUserWithEmailAndPassword(email, password)).pipe(
      map((credential: firebase.auth.UserCredential): firebase.User => credential.user)
    );
  }

  async logout(): Promise<void> {
    await this.firestoreAuth.signOut();
    await this.toLogin();
  }

  async toLogin(): Promise<boolean>{
    return this.router.navigate(['login']);
  }

  logoutFromTemporalApp(): Observable<void> {
    return from(this.getSecondaryApp().auth().signOut());
  }

  sendEmailVerification(email: string): Observable<void> {
    const actionCodeSettings = {
      url: CONSTANTS.EMAIL_ACTIONS_URL + email,
    };
    return from(this.getSecondaryApp().auth().currentUser.sendEmailVerification(actionCodeSettings));
  }

  sendEmailPasswordReset(email: string): Observable<void> {
    const actionCodeSettings = {
      url: CONSTANTS.EMAIL_ACTIONS_URL + email,
    };
    return from(this.firestoreAuth.sendPasswordResetEmail(email, actionCodeSettings));
  }

  verifyPasswordResetCode(actionCode: string): Observable<string> {
    return from(this.firestoreAuth.verifyPasswordResetCode(actionCode));
  }

  confirmPasswordReset(actionCode: string, newPassword: string): Observable<void> {
    return from(this.firestoreAuth.confirmPasswordReset(actionCode, newPassword));
  }

  checkActionCode(actionCode: string): Observable<any> {
    return from(this.firestoreAuth.checkActionCode(actionCode));
  }

  applyActionCode(actionCode: string): Observable<void> {
    return from(this.firestoreAuth.applyActionCode(actionCode));
  }

  getEmail(): string {
    return this.userDetails.email;
  }

  private updateUserData(authUser: firebase.User) {
    return firstValueFrom(
      this.userService.get(authUser.uid).pipe(
        map((user: UserInterface): UserInterface => {
          if (user) {
            user.emailVerified = authUser.emailVerified;
            user.lastSignInTime = firebase.firestore.Timestamp.now();
            this.userDetails = user;
          }
          return this.userDetails;
        })
      )
    );
  }

  private updateSignIngTime(user: UserInterface) {
    user.lastSignInTime = firebase.firestore.Timestamp.now();
    this.userService.update(user.uid, user);
  }

  // Vale la pena revisar si es mejor usar el SDK de admin de Firebase para estas tareas https://firebase.google.com/docs/auth/admin/manage-users#create_a_user
  private getSecondaryApp(): firebase.app.App {
    /**
     * Workaround https://stackoverflow.com/questions/37517208/firebase-kicks-out-current-user/38013551#38013551
     * When creating a new user, createUserWithEmailAndPassword method automatically logs in the new user, kicking out admin user
     * To prevent this, it will be created a secondary instance of firebase to use only for this action
     */
    if (firebase.apps.length) {
      let exists = -1;
      for (let index = 0; index < firebase.apps.length; index++) {
        const element = firebase.apps[index];
        exists = element.name === 'Secondary' ? index : -1;
      }
      if (exists > -1) {
        return firebase.apps[exists];
      } else {
        return firebase.initializeApp(environment.firebaseConfig, 'Secondary');
      }
    } else {
      return firebase.initializeApp(environment.firebaseConfig, 'Secondary');
    }
  }
}
